import { SubType } from '../internalTypes'
import { IAction } from '..'
import { namespaced } from './namespaced'
import { processState } from '../utils'

interface LazyConfiguration {
  [namespace: string]: () => Promise<{
    onInitialize?: any
    state?: {}
    effects?: {}
    actions?: {}
    reactions?: {}
  }>
}

export function lazy<T extends LazyConfiguration, B = T>(
  configurations: T
): {
  onInitialize?: any
  state: SubType<
    {
      [P in keyof T]?: ReturnType<T[P]> extends Promise<infer U>
        ? U extends { state: any }
          ? U['state']
          : never
        : never
    },
    object
  >
  effects: SubType<
    {
      [P in keyof T]?: ReturnType<T[P]> extends Promise<infer U>
        ? U extends { effects: any }
          ? U['effects']
          : never
        : never
    },
    object
  > & {
    lazy: {
      loadConfig: (config: keyof T) => Promise<void>
    }
  }
  actions: SubType<
    {
      [P in keyof T]?: ReturnType<T[P]> extends Promise<infer U>
        ? U extends { actions: any }
          ? U['actions']
          : never
        : never
    },
    object
  > & {
    lazy: {
      loadConfig: IAction<any, keyof T>
    }
  }
} {
  let app
  return {
    onInitialize(_, value) {
      app = value
    },
    effects: {
      lazy: {
        loadConfig(config) {
          return app.actions.lazy.loadConfig(config)
        },
      },
    },
    actions: {
      lazy: {
        loadConfig({ state, ...rest }, key) {
          const configToLoad = configurations[key]

          return configToLoad().then((loadedConfig) => {
            const newConfig = namespaced({
              [key]: loadedConfig,
            })

            if (newConfig.state && newConfig.state[key])
              state[key] = processState(newConfig.state[key])
            if (newConfig.effects && newConfig.effects[key])
              app.effects[key] = newConfig.effects[key]
            if (newConfig.actions && newConfig.actions[key])
              app.actions[key] = app.getActions(newConfig.actions[key])
            if (newConfig.onInitialize)
              newConfig.onInitialize(
                {
                  state,
                  ...rest,
                },
                app
              )
          })
        },
      },
    },
  } as any
}
